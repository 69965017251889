<template>
  <div :class="classes">
    <slot name="header">
      <div v-if="title || desc" class="block-header">
        <div>
          <div v-if="title" class="block-title">{{ title }}</div>
          <div v-if="desc" class="block-desc">{{ desc }}</div>
        </div>
        <router-link v-if="to" :to="to" class="block-link"><Arrow direction="right" /></router-link>
        <div class="block-link"><slot name="header-right"></slot></div>
      </div>
    </slot>
    <slot></slot>
  </div>
</template>

<script>
import Arrow from '@/components/ui/Arrow'

export default {
  name: 'Block',
  components: {
    Arrow
  },
  props: {
    to: [String, Object],
    title: String,
    desc: String,
    noline: Boolean,
    shadow: Boolean,
    small: Boolean,
    mb5: Boolean,
    mb10: Boolean,
    grey: Boolean
  },
  computed: {
    classes () {
      return {
        block: true,
        'block-noline': this.noline,
        'block-shadow': this.shadow,
        'block-small': this.small,
        'block-mb-5': this.mb5,
        'block-mb-10': this.mb10,
        grey: this.grey
      }
    }
  }
}
</script>

<style>
.block {
  display: block;
  background: #fff;
  border-radius: 10px;
  padding: 30px 40px 40px;
  margin-bottom: 30px;
}
.block.block-mb-5 {
  margin-bottom: 5px;
}
.block.block-mb-10 {
  margin-bottom: 10px;
}
.block.grey {
  background: var(--bg-color);
}
a.block:hover {
  text-decoration: none;
}
.block-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}
.block-noline .block-header {
  border-bottom: none;
  padding-bottom: 0;
}
.block-header .block-title + .block-desc {
  margin-bottom: 10px;
}
.block-header .block-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--title-color);
}
.block-header .block-desc {
  font-size: 13px;
  line-height: 15px;
  color: var(--text-color);
  margin-top: 10px;
}
.block-header .block-link {
  margin-top: 10px;
}
.block.block-shadow {
  box-shadow: 0px 0px 15px rgba(185, 195, 216, 0.5) !important;
}
.block.block-small {
  padding: 20px 25px 25px;
}
.block.block-small .block-header {
  border: none;
  padding-bottom: 10px;
}
</style>
