<template>
  <Block shadow small class="performance">
    <div class="block-header">
      <div>
        <div class="block-desc mt-0 mb-10">{{ dateFormatted }}</div>
        <div class="block-title">Ключевые показатели</div>
      </div>
    </div>
    <div v-if="isLoading"><Loader /></div>
    <div v-else-if="error" class="text-danger">{{ error }}</div>
    <div v-else class="performance__body">
      <div class="performance__list">
        <div
          class="list__item"
          v-for="(item, key) in list"
          :key="key"
        >
          <span class="item__title">{{ item.title }}</span>
          <span class="item__value">
            <span v-if="item.type === 'money'" class="currency">{{ item.value | money }}</span>
            <span v-else-if="item.type === 'percent'">{{ item.value }}%</span>
            <span v-else-if="item.type === 'day'">{{ item.value }} дней</span>
            <span v-else>{{ item.value }}</span>
          </span>
        </div>
      </div>
    </div>
  </Block>
</template>

<script>
export default {
  name: 'Dashboard',
  data () {
    return {
      isLoading: true,
      dateFormatted: this.$moment().format('MMMM YYYY'),
      error: '',
      list: []
    }
  },
  async created () {
    try {
      const result = await this.$api.query('sale/report/performance')
      this.list = result
    } catch (e) {
      this.error = 'Произошла ошибка при получении данных'
    }

    this.isLoading = false
  }
}
</script>

<style scoped>
.block.performance .block-header {
  padding-bottom: 20px;
}
.block.performance .block-header .block-desc {
  text-transform: capitalize;
}
.list__item {
  padding: 19.5px 0;
  border-top: 1px dashed var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--title-color);
  font-weight: 600;
}
.list__item:last-child {
  padding-bottom: 0;
}
.item__value {
  background-color: var(--bg-color);
  border-radius: 100px;
  padding: 5px 12px;
}
.list__item:hover .item__value {
  background-color: #4288F1;
  color: var(--bg-color);
}
</style>
