import { MONTH_SHORT } from '@/common/consts'

const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

const isYesterday = (someDate) => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return someDate.getDate() === yesterday.getDate() &&
    someDate.getMonth() === yesterday.getMonth() &&
    someDate.getFullYear() === yesterday.getFullYear()
}

const isCurrentYear = (someDate) => {
  return someDate.getFullYear() === (new Date()).getFullYear()
}

export default d => {
  if (!d) {
    return ''
  }
  if (typeof d === 'string' || d instanceof String) {
    d = new Date(d)
  } else if (typeof d === 'number' || d instanceof Number) {
    d = new Date(d * 1000)
  }

  const day = d.getDate()
  let month = d.getMonth()
  const year = d.getFullYear()
  const hours = d.getHours()
  const minutes = d.getMinutes()

  let dateTime = ''
  if (hours > 0 || minutes > 0) {
    dateTime = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes)
  }

  if (isToday(d)) {
    return 'сегодня ' + dateTime
  }
  if (isYesterday(d)) {
    return 'вчера ' + dateTime
  }

  if (isCurrentYear(d)) {
    return day + ' ' + MONTH_SHORT[month] + ' ' + dateTime
  }

  month++

  return day + '.' + (month < 10 ? '0' + month : month) + '.' + year + ' ' + dateTime
}
