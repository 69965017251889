<template>
  <div>
    <div
      :id="`arrow_${id}`"
      class="arrow"
      :class="[direction, {'border-0': border === false}]"
      @click="$emit('arrow-click')"
    >
      <SpriteIcon icon="check" />
    </div>
    <b-tooltip
      v-if="tooltipText.length > 0"
      :target="`arrow_${id}`"
      :title="tooltipText"
    ></b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'Arrow',
  props: {
    direction: {
      type: String,
      default: 'bottom'
    },
    tooltipText: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = this._uid
  }
}
</script>

<style scoped>
.arrow {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid var(--border-color);
}
.page-top .arrow {
  border-color: var(--bg-color);
}
.tabs-show-arrow .arrow {
  position: absolute;
  right: 0;
  top: -5px;
}
</style>
<style>
.arrow svg {
  color: var(--text-color);
}
.arrow:hover svg, a:hover .arrow svg {
  color: var(--title-color);
}
.arrow.top svg {
  transform: rotate(180deg);
}
.arrow.left svg {
  transform: rotate(90deg) translateX(-1px);
}
.arrow.right svg {
  transform: rotate(-90deg) translateX(1px);
}
</style>
