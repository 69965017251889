<template>
  <div class="entity-select">
    <div class="select-wrapper" :class="{'opened': isOpened}" v-on-clickaway="onClose">
      <v-select
        ref="select"
        :class="{ 'is-invalid': state === false }"
        v-model="selected"
        :label="label"
        :reduce="el => parseInt(el[id])"
        :getOptionKey="op => parseInt(op[id])"
        :options="options"
        :filterable="true"
        :placeholder="placeholder"
        :loading="isLoading"
        :selectable="option => this.url === 'user' && option.active === true"
        @close="onClose"
        @open="onOpen"
        @search="onSearch"
      >
        <template #no-options>Ничего не найдено</template>
        <template #open-indicator><span></span></template>
        <template v-if="this.url === 'user'" #option="{ name, avatar, active }">
          <Avatar :photo="avatar" :title="name" :desc="!active ? 'Уволен' : ''" />
        </template>
        <li v-if="this.url === 'user'" slot="list-footer">
          <hr>
          <div class="list-footer" :class="{ 'open': showNotActiveUsers !== false }">
            <div class="list-footer-title mb-3 pl-25 pr-25 title-color" @click="showNotActiveUsers = !showNotActiveUsers" role="button">
              <img :src="require(`@/assets/svg/check_icon.svg`)"> Уволенные сотрудники
            </div>
            <ul
              class="p-0"
              :class="{ 'd-none': showNotActiveUsers === false }"
            >
              <li
                v-for="option in notActiveUsers"
                :key="option.id"
                role="option"
                class="vs__dropdown-option"
                @click.prevent.stop="selected = option.id"
              >
                <Avatar :photo="option.avatar" :title="option.name" :desc="!option.active ? 'Уволен' : ''" />
              </li>
            </ul>
          </div>
        </li>
      </v-select>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import Avatar from '@/components/ui/Avatar'

export default {
  name: 'EntitySelect2',
  components: {
    Avatar
  },
  props: {
    url: String,
    label: {
      type: String,
      default: 'title'
    },
    source: String,
    placeholder: String,
    state: Boolean
  },
  directives: {
    onClickaway: onClickaway
  },
  data () {
    return {
      selected: null,
      options: [],
      isOpened: false,
      isLoading: false,
      showNotActiveUsers: false
    }
  },
  computed: {
    id () {
      let field = 'id'

      if (this.url === 'user' && this.source) {
        field = this.source + '_user_id'
      } else if (this.source) {
        field = 'external_id'
      }

      return field
    },
    notActiveUsers () {
      return this.options.filter(el => el.active === false && el.name.toLocaleLowerCase().indexOf(this.$refs.select.search.toLocaleLowerCase()) > -1)
    }
  },
  watch: {
    selected () {
      this.$emit('input', this.selected)
      document.activeElement.blur()
    }
  },
  methods: {
    async fetchData () {
      if (this.options.length === 0) {
        this.isLoading = true
        this.options = await this.$api.query(this.url + (this.source ? '/external/' + this.source : ''))
        this.isLoading = false
      }
    },
    async onOpen () {
      await this.fetchData()
      this.isOpened = true
    },
    onClose () {
      this.isOpened = false
    },
    onSearch (search, loading) {
      this.showNotActiveUsers = true
    }
  }
}
</script>
<style scoped>
.entity-select {
  position: relative;
  min-height: 40px;
  top: 20px;
}
.select-wrapper {
  background: #FFFFFF;
  position: absolute;
  width: 100%;
  top: -20px;
}
.select-wrapper.opened {
  box-shadow: 0px 0px 15px rgba(185, 195, 216, 0.5);
  border-radius: 5px;
  width: calc(100% + 50px);
  left: -25px;
  top: -45px;
  padding: 25px 0;
}
</style>
<style>
.entity-select:not([value]) .v-select:not(.vs--open) .vs__dropdown-toggle {
  background-image: url(../assets/svg/search_icon.svg);
  background-size: 14px 15px;
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 35px;
}
.entity-select .vs--open .vs__dropdown-toggle {
  margin-left: 25px;
  margin-right: 25px;
}
.entity-select:not([value]) .v-select.is-invalid:not(.vs--open) .vs__dropdown-toggle {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"), url(../assets/svg/search_icon.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center, 12px center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem), 14px 15px;
}
.entity-select .vs__dropdown-option {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
}
.entity-select .vs__dropdown-menu {
  position: relative;
  border: none;
  box-shadow: none;
  border-radius: 0;
  max-height: 260px;
  padding: 0;
  margin-top: 25px;
  top: auto;
  left: auto;
}
.entity-select .vs--open .vs__dropdown-toggle {
  border-bottom-color: var(--border-color);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.entity-select .vs__dropdown-option--highlight, .entity-select .vs__dropdown-option--selected, .entity-select .vs__dropdown-option:hover {
  background: none;
}
.entity-select .vs__dropdown-option--highlight .avatar-title, .entity-select .vs__dropdown-option--selected .avatar-title, .entity-select .vs__dropdown-option:hover .avatar-title {
  color: var(--text-color);
}
.entity-select .vs__selected-options {
  overflow: visible !important;
}
.entity-select .vs__dropdown-option--disabled {
  display: none;
}
.entity-select .list-footer .list-footer-title img {
  margin-top: -2px;
}
.entity-select .list-footer:not(.open) .list-footer-title img {
  transform: rotate(-90deg);
}
</style>
