import Vue from 'vue'
import YesnoFilter from '@/filters/yesno.filter'
import MoneyFilter from '@/filters/money.filter'
import FinmoneyFilter from '@/filters/finmoney.filter'
import DatetimeFilter from '@/filters/datetime.filter'
import MonthFilter from '@/filters/month.filter'
import CapitalizeFilter from '@/filters/capitalize.filter'
import PluralizeFilter from '@/filters/pluralize.filter'
import BarColorFilter from '@/filters/barcolor.filter'
import PhoneFilter from '@/filters/phone.filter'

Vue.filter('yesno', YesnoFilter)
Vue.filter('money', MoneyFilter)
Vue.filter('finmoney', FinmoneyFilter)
Vue.filter('datetime', DatetimeFilter)
Vue.filter('month', MonthFilter)
Vue.filter('capitalize', CapitalizeFilter)
Vue.filter('pluralize', PluralizeFilter)
Vue.filter('barcolor', BarColorFilter)
Vue.filter('phone', PhoneFilter)
