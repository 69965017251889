<template>
  <Block shadow small title="Рейтинг">
    <div v-if="isLoading"><Loader /></div>
    <div v-else-if="error" class="text-danger">{{ error }}</div>
    <div v-else class="rating">
      <div class="rating-current">
        <div>
          <div
            v-if="isCurrentUser === false"
            class="rating-current-user"
          >
            {{ user.name }}
          </div>
          <div class="rating-current-place">
            <span v-if="!nonEmpty">Результатов еще нет</span>
            <span v-else-if="!user.sum">У вас еще нет продаж</span>
            <span v-else>Ваше место {{ user.place }}</span>
          </div>
          <div class="rating-current-value">
            <span v-if="!nonEmpty">Станьте первым</span>
            <span v-else-if="!user.sum">Пора потрудиться</span>
            <span v-else-if="isPercent">{{ user.percent }}%</span>
            <span v-else class="currency">{{ user.sum | money }}</span>
          </div>
        </div>
        <div class="rating-runner">
          <img :src="require('@/assets/img/sale/runner.gif')" alt="">
        </div>
      </div>
      <div v-for="item in list" :key="item.id" class="rating-user"
        :class="{ 'opacity-50': item.fired, 'rating-user-divider-top': nonEmpty && Math.min(nonEmpty + 1, 4) === item.place }">
        <div class="rating-user-place">
          <div v-if="item.place <= 3 && item.place <= nonEmpty"><img :src="require('@/assets/svg/plan/' + item.place + '_place.svg')" /></div>
          <div v-else class="rating-user-place-digit"><span v-if="item.sum">{{ item.place }}</span><span v-else>-</span></div>
        </div>
        <Avatar :photo="item.avatar" :title="item.name">
          <template #desc>
            <span v-if="isPercent">{{ item.percent }}%</span>
            <span v-else class="currency">{{ item.sum | money }}</span>
          </template>
        </Avatar>
      </div>
    </div>
  </Block>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '@/components/ui/Avatar'

export default {
  name: 'Rating',
  components: {
    Avatar
  },
  data () {
    return {
      isLoading: true,
      error: '',
      measure: 'R',
      user: {},
      list: []
    }
  },
  computed: {
    ...mapGetters('auth', ['current']),
    ...mapGetters('sale/date', ['dateQuery']),
    userId () {
      return this.$route && this.$route.params.id ? this.$route.params.id : this.current.id
    },
    isPercent () {
      return this.measure === 'P'
    },
    nonEmpty () {
      return this.list.filter(el => el.sum).length
    },
    isCurrentUser () {
      return this.userId === this.current.id
    }
  },
  async created () {
    try {
      const result = await this.$api.query(`sale/rating/${this.userId}${this.dateQuery}`)
      this.measure = result.measure
      this.user = result.user
      this.list = result.list
    } catch (e) {
      this.error = 'Произошла ошибка при получении данных'
    }

    this.isLoading = false
  }
}
</script>

<style scoped>
.rating-current {
  display: flex;
  border-bottom: 1px solid var(--title-color);
  margin-bottom: 25px;
  margin-top: 5px;
}
.rating-current > div:first-child {
  width: 160px;
}
.rating-current-user {
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  margin-bottom: 5px;
}
.rating-current-place {
  display: inline-block;
  background-color: var(--bg-color);
  border-radius: 100px;
  padding: 5px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--title-color);
}
.rating-current-value {
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  margin-top: 10px;
  margin-left: 12px;
}
.rating-runner {
  transform: scale(-1, 1);
  width: 90px;
  height: 85px;
  overflow: hidden;
  position: relative;
}
.rating-runner img {
  max-height: 85px;
  transform: scale(1.1);
  position: absolute;
  bottom: -4px;
  right: -18px;
  image-rendering: crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}
.rating-user {
  display: flex;
  margin-top: 20px;
}
.rating-user-divider-top {
  border-top: 1px solid var(--border-color);
  margin-top: 25px;
  padding-top: 25px;
}
.rating-user-place > div {
  width: 36px;
  height: 36px;
  margin-right: 15px;
}
.rating-user-place .rating-user-place-digit {
  border-radius: 50%;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.rating-user-place .rating-user-place-digit span {
  margin-top: -1px;
}
</style>
