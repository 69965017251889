<template>
  <v-select
    ref="select"
    v-model="selected"
    :label="label"
    :reduce="el => parseInt(el[id])"
    :getOptionKey="op => parseInt(op[id])"
    :options="options"
    :filterable="false"
    @search="onSearch"
    @option:selected="onOptionSelected"
    @close="onClose"
    @open="onOpen"
  >
    <template #no-options>Ничего не найдено</template>
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes"><SpriteIcon icon="check" /></span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'EntitySelect',
  props: {
    value: [Number, String],
    url: String,
    label: {
      type: String,
      default: 'title'
    },
    source: String
  },
  data () {
    return {
      selected: null,
      selectedOption: {},
      options: [],
      isSearching: false
    }
  },
  computed: {
    id () {
      let field = 'id'

      if (this.url === 'user' && this.source) {
        field = this.source + '_user_id'
      } else if (this.source) {
        field = 'external_id'
      }

      return field
    }
  },
  watch: {
    selected () {
      this.$emit('input', this.selected)
    },
    url () {
      this.fetchData()
    }
  },
  async mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.options = []
      this.selected = this.value
      if (this.selected) {
        const result = await this.$api.query(this.url + (this.source ? '/external/' + this.source + '/' : '/') + this.selected)
        this.selectedOption = result
        this.options.push(result)
      }
    },
    async onSearch (search, loading) {
      if (!search.length) {
        this.$refs.select.open = false
        return
      }

      this.$refs.select.open = true
      this.isSearching = true
      loading(true)

      const result = await this.$api.query(this.url + '/search', { q: search })
      this.options = Array.isArray(result) ? result : []

      loading(false)
      this.isSearching = false
    },
    onOptionSelected (op) {
      this.selectedOption = op
    },
    onOpen () {
      if (!this.options.length && !this.isSearching) {
        this.$refs.select.open = false
      }
    },
    onClose () {
      this.options = this.selectedOption && this.selectedOption[this.id] ? [this.selectedOption] : []
    }
  }
}
</script>
