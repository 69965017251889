import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', ['current'])
  },
  methods: {
    can (perm) {
      return this.current && this.current.perms.includes(perm)
    }
  }
}
