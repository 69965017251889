<template>
  <perfect-scrollbar ref="scroll">
    <div v-if="isLoading">Авторизация...</div>
    <div v-else class="wrapper">
      <Menu v-if="!hiddenMenu" />
      <div class="main-content">
        <div v-if="accessDenied">
          <div class="alert alert-danger">Доступ запрещен!</div>
        </div>
        <router-view v-else></router-view>
      </div>
      <Sidebar v-if="showSidebar" />
      <Vuedals />
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import Menu from '@/components/Menu'
import Sidebar from '@/components/Sidebar'
import { Component as Vuedals } from 'vuedals'

export default {
  name: 'App',
  components: {
    Menu,
    Sidebar,
    Vuedals
  },
  data () {
    return {
      isLoading: true,
      accessDenied: false
    }
  },
  computed: {
    ...mapGetters(['isBitrix24']),
    ...mapGetters('auth', ['current']),
    hiddenMenu () {
      return this.$route.name === 'login' || this.isBitrix24
    },
    showSidebar () {
      return this.$route.meta.sidebarComponents
    }
  },
  watch: {
    $route () {
      this.$refs.scroll.$el.scrollTop = 0

      this.accessDenied = (this.$route.meta.perm && !this.current.perms.includes(this.$route.meta.perm)) ||
        (this.$route.matched.length && this.$route.matched[0].meta.perm && !this.current.perms.includes(this.$route.matched[0].meta.perm))
    }
  },
  async created () {
    this.isLoading = false
  }
}
</script>
