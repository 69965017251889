export const BARCOLOR_SUCCESS = 'var(--green-color)'
export const BARCOLOR_WARNING = 'var(--yellow-color)'
export const BARCOLOR_DANGER = 'var(--red-color)'

export default (percent, need = 100) => {
  if (percent >= need) {
    return BARCOLOR_SUCCESS
  } else if (need - percent <= 20) {
    return BARCOLOR_WARNING
  }
  return BARCOLOR_DANGER
}
