import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Api from '@/common/api.service'
import Vuedals from 'vuedals'
import Loader from '@/components/Loader'
import Block from '@/components/Block'
import EntitySelect from '@/components/EntitySelect'
import EntitySelect2 from '@/components/EntitySelect2'
import SpriteIcon from '@/components/ui/icons/SpriteIcon'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/css/index.css'

import '@/filters'
import Jwt from '@/common/jwt.service'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Moment from 'moment'

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.component('v-select', vSelect)
Vue.component('Loader', Loader)
Vue.component('Block', Block)
Vue.component('EntitySelect', EntitySelect)
Vue.component('EntitySelect2', EntitySelect2)
Vue.component('SpriteIcon', SpriteIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuedals)
Vue.use(PerfectScrollbar)

Vue.prototype.$api = Api
Api.init()

Moment.locale('ru')
Vue.prototype.$moment = Moment

// event bus
Vue.prototype.$bus = new Vue({})

// change page title
const DEFAULT_TITLE = 'Bigame'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title + ' | ' + DEFAULT_TITLE : DEFAULT_TITLE
  })
})

store.dispatch('auth/checkAuth').then(() => {
  router.beforeEach(async (to, from, next) => {
    if (to.name !== 'login' && !Jwt.getToken()) {
      return next({ name: 'login' })
    } else if (to.name !== 'logout') {
      if (!Jwt.getToken()) {
        await Promise.all([store.dispatch('auth/checkAuth')])
      }
      if (to.meta.isPanel && from.name) {
        return false
      }
      return next()
    } else {
      return next()
    }
  })

  new Vue({
    router,
    store,
    render: h => h(App),
    mounted () {
      document.addEventListener('click', this.globalClick)
    },
    beforeDestroy () {
      document.removeEventListener('click', this.globalClick)
    },
    methods: {
      async globalClick (e) {
        if (e.target.tagName.toLowerCase() === 'a') {
          store.dispatch('openPanel', { e })
        }
      }
    }
  }).$mount('#app')
})
