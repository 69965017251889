<template>
  <div class="sidebar">
    <component v-for="component in components" :key="`${componentKey}-${component}`" :is="component"></component>
  </div>
</template>

<script>
import Kpi from './sidebar/Kpi'
import Rating from './sidebar/Rating'
import Dashboard from './sidebar/Dashboard'

export default {
  name: 'Sidebar',
  components: {
    Kpi,
    Rating,
    Dashboard
  },
  computed: {
    components () {
      return this.$route.meta.sidebarComponents ?? []
    }
  },
  data () {
    return {
      componentKey: 0
    }
  },
  created () {
    this.$bus.$on('refreshData', () => {
      this.forceRerender()
    })
  },
  methods: {
    forceRerender () {
      this.componentKey += 1
    }
  }
}
</script>

<style>
.sidebar {
  width: 330px;
  flex-shrink: 0;
  margin-left: 40px;
}
</style>
