<template>
  <div class="sidebar-menu" :class="{ 'with-submenu': submenuItems && submenuItems.length, 'menu-short': isShortMenu }">
    <div class="menu">
      <div class="menu-logo" @click="toggleShortMenu">
        <img :src="require('@/assets/img/logo.png')" alt="Bigame"><span class="rx-title">Bigame</span>
      </div>
      <div class="menu-group">
        <div class="menu-group-title rx-title" v-if="activeGroup" @click="selectGroup()">
          {{ activeGroup.title }}
        </div>
        <div class="menu-group-title rx-title" v-else @click="selectGroup()">Выберите модуль</div>
        <div class="menu-group-change"><Arrow :direction="groupArrowDirection" @arrow-click="selectGroup()" /></div>
      </div>
      <div class="menu-items" v-if="activeGroup">
        <router-link
          :to="item.link"
          exact
          class="menu-item"
          :class="{ 'menu-item-disabled': item.disabled }"
          :disabled="item.disabled"
          :event="!item.disabled ? 'click' : ''"
          v-for="item in activeGroup.children.filter(el => !el.perm || can(el.perm))"
          :key="item.title"
        >
          <div class="menu-item-icon menu-short-visible"><svg><use :xlink:href="menuSvg + '#' + (item.icon ? item.icon : 'default')"></use></svg></div>
          <span v-b-tooltip.hover.right :title="item.disabled ? 'Скоро' : ''">{{ item.title }}</span>
        </router-link>
      </div>
      <div class="menu-items" v-else>
        <div class="menu-item" v-for="(item, index) in filteredGroups" :key="index" @click="selectGroup(index)">
          <div class="menu-item-icon menu-short-visible"><svg><use :xlink:href="menuSvg + '#' + (item.icon ? item.icon : 'default')"></use></svg></div>
          <span>{{ item.title }}</span>
        </div>
      </div>
      <div class="menu-divider"></div>
      <div class="menu-profile" v-if="current">
        <div class="menu-profile-avatar menu-short-visible" :style="{ backgroundImage: 'url(' + current.avatar + ')' }"></div>
        <div class="menu-profile-name">{{ current.name }}</div>
      </div>
      <div class="menu-divider mt-auto" v-if="!isBitrix24"></div>
      <router-link :to="{ name: 'logout' }" class="menu-logout" v-if="!isBitrix24">
        <div class="menu-logout-icon menu-short-visible">
          <svg><use :xlink:href="menuSvg + '#logout'"></use></svg>
        </div>
        <span>Выйти</span>
      </router-link>
    </div>
    <Submenu :items="submenuItems" :isLoading="submenuLoading" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import menuSvg from '@/assets/svg/menu.svg'
import Arrow from '@/components/ui/Arrow'
import Submenu from './Submenu'
import CanMixin from '@/mixins/CanMixin.js'

export default {
  name: 'Menu',
  components: {
    Submenu,
    Arrow
  },
  mixins: [CanMixin],
  data () {
    return {
      menuSvg,
      isShortMenu: false,
      groups: [
        {
          title: 'Продажи',
          active: false,
          link: '/sale',
          perm: 'sale_module',
          children: [
            { title: 'Отчеты', link: '/sale/dashboard', perm: 'sale_dashboard' },
            { title: 'План компании', icon: 'plan', link: '/sale/plan/progress', perm: 'sale_settings' },
            { title: 'Личный план', icon: 'plan', link: '/sale/plan', perm: 'sale_plan' },
            /* { title: 'Планирование', icon: 'planning', link: '/sale/planning', disabled: true },
            { title: 'Активность', icon: 'active_board', link: '/sale/active-board', disabled: true }, */
            { title: 'Личные KPI', icon: 'focus_kpi', link: '/sale/kpi', disabled: true },
            /* { title: 'Рейтинг', icon: 'rating', link: '/sale/rating', disabled: true },
            {
              title: 'Арена',
              icon: 'arena',
              link: '/sale/arena',
              disabled: true,
              children: [
                { title: 'Квесты', description: 'Управленческая игра', link: '/sale/arena/quests', icon: require('@/assets/img/arena/quests-menu.png') },
                { title: 'Баттлы', description: 'Поединки сотрудников', link: '/sale/arena/combats', icon: require('@/assets/img/arena/combats-menu.png') }
              ]
            },
            { title: 'Отчеты', icon: 'reports', link: '/sale/reports', disabled: true }, */
            { title: 'Настройки', link: '/sale/settings' }
          ]
        },
        {
          title: 'Финансы',
          active: false,
          link: '/fin',
          perm: 'fin_module',
          disabled: true,
          children: [
            {
              title: 'Деньги',
              link: '/fin/operations',
              perm: 'fin_operation_view'
            },
            { title: 'Счета', link: '/fin/invoices', perm: 'fin_invoice_view' },
            {
              title: 'Денежный поток',
              link: '/fin/cashflow',
              perm: 'fin_cashflow'
            },
            {
              title: 'Настройки',
              link: '/fin/settings',
              perm: 'fin_settings',
              children: [
                { title: 'Категории', description: 'Категории расходов и доходов', link: '/fin/settings/categories' },
                { title: 'Организации', link: '/fin/settings/orgs' },
                { title: 'Счета организаций', link: '/fin/settings/org-invoices' }
              ]
            }
          ]
        },
        {
          title: 'Звонки',
          active: false,
          link: '/call',
          children: [
            { title: 'Пропущенные', link: '/call/missed' },
            { title: 'Настройки', link: '/call/settings' }
          ]
        },
        {
          title: 'Настройки',
          active: false,
          children: [
            { title: 'Общие', link: '/settings/general', disabled: true },
            { title: 'Сотрудники и роли', link: '/settings/rights', perm: 'edit_rights' },
            {
              title: 'Интеграции',
              link: '/integrations',
              perm: 'integration'
            },
            { title: 'Шаблоны документов', link: '/doc-templates', disabled: true }
          ]
        }
      ],
      lastActiveGroup: '',
      groupArrowDirection: 'bottom',
      submenuItems: [],
      submenuLoading: true
    }
  },
  computed: {
    ...mapGetters('auth', ['current']),
    ...mapGetters(['isBitrix24']),
    filteredGroups () {
      return this.groups.filter(el => !el.disabled && (!el.perm || this.can(el.perm)))
    },
    activeGroup () {
      return this.filteredGroups.find(el => el.active)
    }
  },
  watch: {
    async $route () {
      this.submenuLoading = true

      if (!this.$route.matched.length) {
        this.submenuItems = []
        this.submenuLoading = false
        return
      }

      for (let i = 0; i < this.filteredGroups.length; i++) {
        Vue.set(this.filteredGroups[i], 'active', false)
        if (this.isLinkActive(this.filteredGroups[i])) {
          Vue.set(this.filteredGroups[i], 'active', true)
          this.lastActiveGroup = i
          break
        }
      }

      this.submenuItems = await this.getSubmenuItems()
      this.submenuLoading = false
    }
  },
  methods: {
    toggleShortMenu () {
      this.isShortMenu = !this.isShortMenu
    },
    selectGroup (index = -1) {
      const wasActiveGroup = this.filteredGroups.find(el => el.active)
      this.filteredGroups.forEach(el => { el.active = false })
      if (index >= 0) {
        this.filteredGroups[index].active = true
        this.lastActiveGroup = index
        this.groupArrowDirection = 'bottom'
      } else {
        if (!wasActiveGroup) {
          this.filteredGroups[this.lastActiveGroup].active = true
        }
        this.groupArrowDirection = 'top'
      }
    },
    async getSubmenuItems () {
      if (!this.activeGroup || !this.$route.path) {
        return []
      }
      const activeItem = this.activeGroup.children.find(el => this.$route.path.indexOf(el.link) === 0)
      if (!activeItem) {
        return []
      }
      if (typeof activeItem.children === 'undefined' && typeof activeItem.fetchChildren === 'function') {
        activeItem.children = await activeItem.fetchChildren()
      }
      return activeItem.children
    },
    isLinkActive (item) {
      return this.matchRoute(item) || this.isChildActive(item.children)
    },
    isChildActive (child) {
      if (!child) return false
      return child.some(item => {
        return this.isLinkActive(item)
      })
    },
    matchRoute ({ link }) {
      if (!link) return false
      if (this.$router) {
        const { route } = this.$router.resolve(link)
        return route.path === this.$route.path || this.$route.matched.map(el => el.path).includes(route.path)
      } else {
        return link === window.location.pathname
      }
    }
  }
}
</script>

<style scoped>
.menu-logo {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.menu-logo span {
  padding-left: 10px;
}
.sidebar-menu.menu-short .menu-logo span {
  display: none;
}
.sidebar-menu {
  display: flex;
  width: 240px;
  flex-shrink: 0;
}
.sidebar-menu.with-submenu {
  width: 440px;
}
.sidebar-menu.menu-short {
  width: 80px;
}
.menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 240px;
  padding: 0 40px 40px 0;
  background-color: #fff;
  height: 100vh;
  flex-shrink: 0;
  z-index: 10;
}
.menu-divider {
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
  margin: 30px 0;
  flex-shrink: 0;
}
.menu-items {
  margin-top: 30px;
}
.menu-group {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(185, 195, 216, 0.5);
  border-radius: 10px;
  padding: 8px 12px 12px 20px;
  margin-top: 30px;
}
.menu-group-title {
  flex-grow: 1;
  font-weight: 600;
  line-height: 1.4;
  cursor: pointer;
}
.menu-group-change {
  margin-left: auto;
}
.sidebar-menu.menu-short .menu-group {
  display: none;
}
.menu-item {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1.4;
  color: var(--text-color);
  margin-bottom: 25px;
  cursor: pointer;
}
.menu .menu-item:last-child {
  margin-bottom: 0;
}
.menu-item:not(.menu-item-disabled):hover, .menu-item.active {
  color: var(--title-color);
  text-decoration: none;
}
.menu-item-icon {
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.menu-item-icon svg {
  width: 20px;
  height: 20px;
}
.menu-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.menu-profile-avatar {
  background-size: cover;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.menu-profile-name {
  font-weight: 600;
  line-height: 18px;
  color: var(--text-color);
}
.menu-logout {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 20px;
}
.menu-logout:hover {
  color: var(--title-color);
  text-decoration: none;
}
.menu-logout-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.menu-logout-icon svg {
  width: 20px;
  height: 20px;
}
.menu-short .menu {
  align-items: center;
  width: 80px;
}
.menu-short .menu > div > div:not(.menu-item):not(.menu-short-visible),
.menu-short .menu-item > *:not(.menu-short-visible),
.menu-short .menu-logout > *:not(.menu-short-visible) {
  display: none;
}
.menu-short .menu-short-visible {
  margin-right: 0;
}
</style>
