<template>
  <transition name="fadeInLeft">
    <div
      v-if="items && items.length"
      class="submenu"
    >
      <div v-if="isLoading"><Loader /></div>
      <div v-else>
        <router-link
          v-for="el in items"
          :key="el.link"
          :to="el.link"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <div
            class="submenu__item"
            :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
          >
            <a
              class="text-decoration-none"
              :href="href"
              @click="navigate">
              <div class="submenu__title-block">
                <span class="submenu__title rx-title">{{ el.title }}</span>
                <span
                  v-if="el.description"
                  class="submenu__subtitle rx-subtitle"
                >{{ el.description }}</span>
              </div>
              <div
                v-if="el.icon"
                class="submenu__image-block text-center"
              >
                <img :src="el.icon" :alt="el.title">
              </div>
            </a>
          </div>
        </router-link>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Submenu',
  props: {
    items: Array,
    isLoading: Boolean
  }
}
</script>

<style scoped>
.submenu {
  position: fixed;
  left: 280px;
  height: 100vh;
  width: 280px;
  background: var(--bg-color);
  border-right: 1px solid var(--border-color);
  z-index: 5;
}
.submenu .submenu__item {
  border-bottom: 1px solid var(--border-color);
}
.submenu .submenu__title-block {
  padding: 25px 30px 20px;
}
.submenu .submenu__title {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px;
}
.submenu .submenu__subtitle {
  font-size: 13px;
  line-height: 18px;
  display: block;
}
.submenu .submenu__item.router-link-active,
.submenu .submenu__item:hover {
  background: var(--grey-color);
}
.submenu .submenu__image-block {
  padding-bottom: 25px;
}
.fadeInLeft-enter-active, .fadeInLeft-leave-active {
  transition: transform .5s, opacity .5s;
  transform: translateX(0);
  opacity: 1;
}
.fadeInLeft-enter, .fadeInLeft-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
