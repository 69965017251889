export default (number) => {
  if (number.length !== 11 || (number.charAt(0) === '+' && number.length !== 12) || !/^[0-9]*$/.test(number)) {
    return number
  }

  if (number.charAt(0) !== '+') {
    number = '+' + number
  }
  if (number.charAt(1) === '8') {
    number.replace('8', '7')
  }

  return number.slice(0, 2) + ' (' + number.slice(2, 5) + ') ' + number.slice(5, 8) + '-' + number.slice(8, 10) + '-' + number.slice(10)
}
