<template>
  <Block shadow small title="Личные KPI" desc="Здесь отображаются ваши ключевые показатели за текущий месяц">
    <div v-if="isLoading"><Loader /></div>
    <div v-else-if="error" class="text-danger">{{ error }}</div>
    <div v-else>
      <div class="kpi-items">
        <div class="kpi-item">
          <div class="kpi-item-title">Средний чек</div>
          <div class="kpi-item-value">{{ avgSum | money }}</div>
        </div>
        <div class="kpi-item">
          <div class="kpi-item-title">Длительность сделки</div>
          <div class="kpi-item-value">{{ avgDuration | pluralize(['день', 'дня', 'дней']) }}</div>
        </div>
        <div class="kpi-item">
          <div class="kpi-item-title">Колличество новых сделок</div>
          <div class="kpi-item-value">{{ openDeals }}</div>
        </div>
      </div>
    </div>
  </Block>
</template>

<script>
export default {
  name: 'Kpi',
  data () {
    return {
      isLoading: true,
      error: '',
      avgSum: 0,
      avgDuration: 0,
      openDeals: 0
    }
  },
  async created () {
    try {
      const result = await this.$api.query('sale/kpi')
      this.avgSum = result.avg_sum
      this.avgDuration = result.avg_duration
      this.openDeals = result.open_deals
    } catch (e) {
      this.error = 'Произошла ошибка при получении данных'
    }

    this.isLoading = false
  }
}
</script>

<style scoped>
.kpi-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border-color);
  padding-top: 15px;
  margin-top: 15px;
}
.kpi-item-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--title-color);
}
.kpi-item-value {
  background-color: var(--bg-color);
  border-radius: 100px;
  padding: 5px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--title-color);
}
</style>
