import { MONTH_SHORT } from '@/common/consts'

export default d => {
  if (!d) {
    return ''
  }
  if (typeof d === 'string' || d instanceof String) {
    d = new Date(d)
  }

  const month = d.getMonth()
  const year = d.getFullYear()

  return MONTH_SHORT[month] + ' ' + year
}
